import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

async function fetchDomainData(region, domain, setDomainInfo, setLoading) {
  try {
    const response = await fetch(`/${region}/domain/${domain}`);
    if (!response.ok) {
      throw new Error(`Ошибка загрузки данных: ${response.statusText}`);
    }
    const data = await response.json();
    setDomainInfo(data); // Устанавливаем данные в состояние
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false); // Завершаем загрузку
  }
}

const Domain = () => {
  const { region, domain } = useParams();
  const [domainInfo, setDomainInfo] = useState(null); // Данные из API
  const [loading, setLoading] = useState(true); // Статус загрузки

  // Загружаем данные при монтировании компонента
  useEffect(() => {
    fetchDomainData(region, domain, setDomainInfo, setLoading);
  }, [region, domain]); // Перезапускать при изменении region или domain

  return (
    <div>
      {loading ? (
        <h1>Загрузка...</h1> // Индикатор загрузки
      ) : (
        <DomainView data={domainInfo} />
      )}
    </div>
  );
};


export default Domain;

const PrimaryBorder = ({children}) =>{
  return(
  <div className="border-2 bg-white border-accent rounded-2xl shadow-md shadow-secondary p-4">
    {children}{}
  </div>
  )
}

const Sidebar = () => {
  return (
    <div className="w-64 bg-primary text-white p-4">
      <h2 className="text-xl font-rubik mb-4">Меню</h2>
      <ul>
        <li className="mb-2 ">
          <a href="#" className="hover:text-gray-400 font-rubik">Пункт 1</a>
        </li>
        <li className="mb-2">
          <a href="#" className="hover:text-gray-400">Пункт 2</a>
        </li>
        <li className="mb-2">
          <a href="#" className="hover:text-gray-400">Пункт 3</a>
        </li>
        <li className="mb-2">
          <a href="#" className="hover:text-gray-400">Пункт 4</a>
        </li>
      </ul>
    </div>
  );
};
const items = [
  { id: 1 },
  { id: 1 },
  { id: 1 },
  { id: 1 },
  { id: 1 },
  // More items...
]

const name = "Магазины на Ozon";
const shops = ["Магазин 1", "Магазин 2", "Магазин 3", "Магазин 4"];
const content = [
  "Описание магазина 1",
  "Описание магазина 2",
  "Описание магазина 3",
  "Описание магазина 4",
];

// Создание объекта с названием и списком магазинов
const ozonSellersBlock = {
  name: name,
  tabs: shops.map((shop, index) => ({
    name: shop,
    content: content[index]
  }))
};

const ozonSeller = {name: "name", content: "testContent"}

const ozonSellers =[
  ozonSeller,
  ozonSeller,
  ozonSeller
]


const DomainView = () => {
  return (
    <div className="flex h-screen">
      <ul role="list" className="divide-y divide-gray-200">

        <OzonSellerInfo tabs={ozonSellersBlock.tabs}/>
      </ul>
      
    </div>
  );
};




const OzonSellerInfo = ({tabs}) => {
  return (
    <div>
      <PrimaryBorder>
        <div className="desktop:px-4 py-2 tablet:px-0">
          <h3 className="text-2xl font-rubik font-semibold text-gray-900">Магазины на Ozon</h3>
        </div>
        <div className=" border-t-2 border-primary py-4 " />
        <Tabs tabs={tabs} />
        
        

      </PrimaryBorder>
    </div>
  )
}





const Block = ({seller}) =>{
  return(
  <div>
    <div className="px-4 py-2 tablet:px-0">
          <h3 className="text-xl font-rubik font-semibold text-gray-900">{seller.name}</h3>
        </div>
        <div className=" border-t border-primary">
        
          <dl className="divide-y border-primary">
            <div className="px-4 py-6 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 tablet:col-span-2 tablet:mt-0">seller.content</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Application for</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Backend Developer</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">margotfoster@example.com</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Salary expectation</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">$120,000</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">About</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
              </dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">Attachments</dt>
              <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      
                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">resume_back_end_developer.pdf</span>
                        <span className="flex-shrink-0 text-gray-400">2.4mb</span>
                      </div>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                        Download
                      </a>
                    </div>
                  </li>
                  <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                    <div className="flex w-0 flex-1 items-center">
                      
                      <div className="ml-4 flex min-w-0 flex-1 gap-2">
                        <span className="truncate font-medium">coverletter_back_end_developer.pdf</span>
                        <span className="flex-shrink-0 text-gray-400">4.5mb</span>
                      </div>
                    </div>

                    <DefaultButton/>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
  </div>
  )
}

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="w-full">
      {/* Таб навигации */}
      <div className="flex overflow-x-auto border-b-2">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-6 py-2 text-lg font-semibold  ${
              activeTab === index
                ? "border-b-2 border-purple-500 text-purple-500"
                : "text-gray-600 hover:text-purple-500"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="mt-4 p-4 border-2 border-gray-200 rounded-md">
        <Block seller={tabs[activeTab]}/>
      </div>
    </div>
  );
};
const DefaultButton = () =>{

  return(
    <button className="btn-default"> 
                      Кнопка
                    </button>
  )
}


const DomainHeader = (domain) =>{
  <PrimaryBorder>
    <div className="desktop:px-4 py-2 tablet:px-0">
          <h3 className="text-2xl font-rubik font-semibold text-gray-900">Магазины на Ozon</h3>
        </div>
  </PrimaryBorder>
}