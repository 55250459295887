const SearchLine = () =>
    {
    
      return (
        <div id="search-line" className="flex flex-1  self-stretch gap-x-6 ">
          <form className="relative flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <input 

              id="search-field"
              className="block h-full w-full py-5 px-10 border-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
            />
          </form>
        </div>
      )
    }

export default SearchLine;