import React from 'react';
import Home from "./components/Home";
import Domain from "./components/domain/Domain";
import { Navigate } from 'react-router-dom';
import About from './components/About';

const getRegionByLanguage = () => {
  const language = navigator.language || navigator.userLanguage; 
  const region = language.startsWith('ru') ? 'ru' : 'en';
  return region;
};


const AppRoutes = [
  {
    index: true,
    path: '/',
    element: <Navigate to={`/${getRegionByLanguage()}`}/>
  },
  {
    path: '/:region', 
    element: <Home />
  },
  {
    path: '/about', 
    element: <About />
  },
  {
    path: "/:region/domain/:domain*", 
    element: <Domain />
  }
];

export default AppRoutes;

