import React from 'react';

const Home = () => {

  return (
    <div className=" text-blackPrimary shadow-md">
      <div className="mx-auto max-w-7xl px-4 py-6">
          Дом
      </div>
  </div>


  );
}

export default  Home;
