import { Fragment, useState } from 'react'

import { Link } from 'react-router-dom';
import SearchLine from './search/SearchLine'

import { Disclosure, DisclosurePanel, DisclosureButton, useClose } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function NavMenu() {
  const [isOpen, setIsOpen] = useState(false)
  const handleLinkClick = () => {
    setIsOpen(false)  // Закрытие меню при клике на ссылку
  }

  return (
    <div className="bg-white text-blackPrimary shadow-md">
      <div className="mx-auto desktop:max-w-screen-desktop px-4">
        <div className="flex h-16 items-center justify-between">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/" className="text-2xl font-rubik font-bold transition-colors duration-300  text-accent hover:text-primary">
              Tapki.com
            </Link>
         </div>
        <SearchLine>
          
        </SearchLine>
        {/* Navigation for Desktop */}
          <div className="hidden desktop:flex space-x-4">
            
            <Link to="/about" className="text-xl font-rubik font-bold transition-colors duration-300 text-accent hover:text-primary">
              О нас
            </Link>
            <Link to="/contact" className="text-xl font-rubik font-bold transition-colors duration-300 text-accent hover:text-primary">
              Контакты
            </Link>
          </div>

          {/* Mobile Menu */}

          <div className="flex desktop:hidden">
            <Disclosure as="div"   >
              {({ open, close }) => (
                <>
                  <DisclosureButton className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium bg-secondary text-white rounded-md hover:bg-accent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                    Меню
                  </DisclosureButton>
                  
                  <DisclosurePanel className="absolute top-16 left-0 w-full bg-white shadow-md text-blackPrimary desktop:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                      <Link to="/about"
                        className="block px-3 py-2 rounded-md text-base font-medium hover:bg-secondary hover:text-white"
                        onClick={close}
                      >
                        О нас
                      </Link>
                      <Link to="/contact"
                        className="block px-3 py-2 rounded-md text-base font-medium hover:bg-secondary hover:text-white"
                        onClick={(event) => {
                          close()}
                        }
                      >
                        Контакты
                      </Link>
                    </div>
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
          </div>

        </div>
      </div>
    </div>
  );
}

