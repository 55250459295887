import { Link } from 'react-router-dom';
export default function About() {

    return(

    <div className="text-blackPrimary font-rubik">

        <div id="About" className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-blackPrimary">О сервисе</h2>
            <p className="text-base leading-relaxed text-blackSecondary">
                tapki.com - это сервис, который позволяет вам быстро искать среди огромного множества сайтов в сети Интернет нужные вам данные. Наша задача — сделать удобный механизм для поиска клиентов вашему бизнесу по всему миру. Мы не взламываем сайты, не подбираем пароли, не ищем уязвимости. Все что мы делаем — структурируем общедоступную (публичную) информацию с возможностью выгрузить ее в формате Microsoft Excel для удобной работы менеджеров.
            </p>
        </div>

   
        <div id="Details" className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-blackPrimary">Реквизиты юридического лица</h2>
            <p className="text-base leading-relaxed text-blackSecondary mb-2">
                ООО “РЕФОРМА”, ИНН 7813229833, КПП 781001001, ОГРН 1157847301853, +7 (812) 748-20-96. Юридический адрес: Россия 196084, г. Санкт-Петербург, ул. Заозерная, дом 8, корпус 2, литер А, помещение 1Н, комната 227
            </p>
            <p className="text-base leading-relaxed text-blackSecondary mb-2">
                Р/С 40702810770010317171 В МОСКОВСКИЙ ФИЛИАЛ АО КБ «МОДУЛЬБАНК» К/cч. 30101810645250000092, БИК 044525092.
            </p>
            <p className="text-base leading-relaxed text-blackSecondary mb-2">
                Почтовый адрес: Россия 196084, Санкт-Петербург, ул. Заозерная, дом №8, корпус 2, Литера А, БЦ “ЗАОЗЕРНЫЙ”, офис 227
            </p>
            <p className="text-base leading-relaxed text-blackSecondary mb-4">
                Генеральный директор Кульгин М.В.
            </p>


            <div className="space-y-2">
                <p>
                    <a href="https://www.tinkoff.ru/business/contractor/legal/1157847301853/" target="_blank" rel="noopener" className="hover:text-primary text-accent">Должная осмотрительность</a></p>
                <p>
                    <Link to="/:region/privacy" className="hover:text-primary text-accent">Политика в отношении обработки персональных данных</Link>
                </p>
                <p>
                    <Link to="/:region/license" className="hover:text-primary text-accent">Лицензионный договор</Link>
                </p>
            </div>
        </div>
    </div>

    )
}